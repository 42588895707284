/*----------------------------------------------

[Core CSS]

Theme   : Shock
Version : 1.0.0
Author  : Codings
Support : codings.dev

----------------------------------------------*/

/*----------------------------------------------

[Content Index]

1. General
2. Layout
3. Color
4. Style
5. Animation
6. Hover
7. Helper

----------------------------------------------*/

/*----------------------------------------------
1. General
----------------------------------------------*/

/* #region General */

body {
	width: 100%;
	height: 100%;
  margin: 0;
	font-family: var(--primary-font);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--gray-color);
}

a {
	text-decoration: none;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

hr {
  margin: 1.5rem 0;
  color: var(--color);
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

ol, ul {
  padding-left: 1.2rem;
}

ol, ul, dl {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

ol:last-child, ul:last-child, dl:last-child {
	margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

ol {
	list-style-type: none;
	counter-reset: item;
}

ol > li, ul > li {
	margin: 0.5rem 0;
}

ol > li:first-child, ul > li:first-child,
ol > li:last-child, ul > li:last-child {
	margin: 0;
}

ol > li {
	counter-increment: item;
}

ol > li::before {
	content: counters(item, ".") ". ";
	margin-left: -1.2rem;
	font-weight: 700;
}

li ol > li::before {
	content: counters(item, ".") " ";
	margin-right: 5px;
}

mark, .mark {
  padding: 0;
}

.blockquote {
	position: relative;
	width: fit-content;
	margin: 3rem auto 3rem 0;
  font-size: inherit;
	color: inherit;
}

.blockquote:last-child {
	margin-bottom: 0;
}

.blockquote-body {
	color: var(--color);
}

.blockquote-footer {
	margin: 0;
	font-size: inherit;
	color: inherit;
}

.blockquote-footer::before {
  content: "— ";
}

.blockquote .overlay-icon {
	position: absolute;
	bottom: 0;
	right: 0;
	display: block;
	font-size: 2.5rem;
	line-height: 0.8;
	color: var(--color);
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
}

.figure {
	width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: block;
}

.figure .image {
	width: 100%;
  display: block;
}

.figure-caption {
  margin-top: 1rem;
	font-size: 0.85rem;
	color: inherit;
}

img, svg {
  vertical-align: middle;
}

@media (max-width: 991px) {	
	.blockquote {
		margin: 1.5rem auto 1.5rem 0;
	}
}

/* #endregion General */

/*----------------------------------------------
2. Layout
----------------------------------------------*/

/* #region Layout */

.w-25 { width: 25% !important; }
.w-35 { width: 35% !important; }
.w-50 { width: 50% !important; }
.w-65 { width: 65% !important; }
.w-75 { width: 75% !important; }
.w-85 { width: 85% !important; }
.w-100 { width: 100% !important; }
.w-auto { width: auto !important; }

@media (min-width: 1200px) {
	.max-w-25 { max-width: 25% !important; }
	.max-w-35 { max-width: 35% !important; }
	.max-w-50 { max-width: 50% !important; }
	.max-w-65 { max-width: 65% !important; }
	.max-w-75 { max-width: 75% !important; }
	.max-w-85 { max-width: 85% !important; }
	.max-w-100 { max-width: 100% !important; }
}

.min-w-25 { min-width: 25% !important; }
.min-w-35 { min-width: 35% !important; }
.min-w-50 { min-width: 50% !important; }
.min-w-65 { min-width: 65% !important; }
.min-w-75 { min-width: 75% !important; }
.min-w-85 { min-width: 85% !important; }
.min-w-100 { min-width: 100% !important; }

.h-25 { height: 25% !important; }
.h-35 { height: 35% !important; }
.h-50 { height: 50% !important; }
.h-65 { height: 65% !important; }
.h-75 { height: 75% !important; }
.h-85 { height: 85% !important; }
.h-100 { height: 100% !important; }
.h-auto { height: auto !important; }

.vh-25 { height: 25vh !important; }
.vh-35 { height: 35vh !important; }
.vh-50 { height: 50vh !important; }
.vh-65 { height: 65vh !important; }
.vh-75 { height: 75vh !important; }
.vh-85 { height: 85vh !important; }
.vh-100 { height: 100vh !important; }
.vh-auto { height: 100vh !important; }

@media (max-width: 1399px) {
	.vh-auto { height: 120vh !important; }
}

@media (max-width: 767px) {
	.vh-auto { height: 130vh !important; }
}

.max-vh-25 { max-height: 25vh !important; }
.max-vh-35 { max-height: 35vh !important; }
.max-vh-50 { max-height: 50vh !important; }
.max-vh-65 { max-height: 65vh !important; }
.max-vh-75 { max-height: 75vh !important; }
.max-vh-85 { max-height: 85vh !important; }
.max-vh-100 { max-height: 100vh !important; }

.min-vh-25 { min-height: 25vh !important; }
.min-vh-35 { min-height: 35vh !important; }
.min-vh-50 { min-height: 50vh !important; }
.min-vh-65 { min-height: 65vh !important; }
.min-vh-75 { min-height: 75vh !important; }
.min-vh-85 { min-height: 85vh !important; }
.min-vh-100 { min-height: 100vh !important; }

.g-0, .gx-0 { --bs-gutter-x: 0 !important; }
.g-0, .gy-0 { --bs-gutter-y: 0 !important; }
.g-1, .gx-1 { --bs-gutter-x: calc( 1rem / 2 ) !important; }
.g-1, .gy-1 { --bs-gutter-y: calc( 1rem / 2 ) !important; }
.g-2, .gx-2 { --bs-gutter-x: calc( 2rem / 2 ) !important; }
.g-2, .gy-2 { --bs-gutter-y: calc( 2rem / 2 ) !important; }
.g-3, .gx-3 { --bs-gutter-x: calc( 3rem / 2 ) !important; }
.g-3, .gy-3 { --bs-gutter-y: calc( 3rem / 2 ) !important; }
.g-4, .gx-4 { --bs-gutter-x: calc( 4rem / 2 ) !important; }
.g-4, .gy-4 { --bs-gutter-y: calc( 4rem / 2 ) !important; }
.g-5, .gx-5 { --bs-gutter-x: calc( 5rem / 2 ) !important; }
.g-5, .gy-5 { --bs-gutter-y: calc( 5rem / 2 ) !important; }

@media (min-width: 768px) {
	.g-1, .gx-1 { --bs-gutter-x: 1rem !important; }
	.g-1, .gy-1 { --bs-gutter-y: 1rem !important; }
	.g-2, .gx-2 { --bs-gutter-x: 2rem !important; }
	.g-2, .gy-2 { --bs-gutter-y: 2rem !important; }
	.g-3, .gx-3 { --bs-gutter-x: 3rem !important; }
	.g-3, .gy-3 { --bs-gutter-y: 3rem !important; }
	.g-4, .gx-4 { --bs-gutter-x: 4rem !important; }
	.g-4, .gy-4 { --bs-gutter-y: 4rem !important; }
	.g-5, .gx-5 { --bs-gutter-x: 5rem !important; }
	.g-5, .gy-5 { --bs-gutter-y: 5rem !important; }
}

.m-auto { margin: auto !important; }
.mx-auto { margin-right: auto; margin-left: auto !important; }
.my-auto { margin-top: auto; margin-bottom: auto !important; }
.mt-auto { margin-top: auto !important; }
.me-auto { margin-right: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ms-auto { margin-left: auto !important; }

.m-0 { margin: 0 !important; }
.mt-0 { margin-top: 0 !important; }
.me-0 { margin-right: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.ms-0 { margin-left: 0 !important; }

.m-05 { margin: calc( 0.5rem / 2 ) !important; }
.mt-05 { margin-top: calc( 0.5rem / 2 ) !important; }
.me-05 { margin-right: calc( 0.5rem / 2 ) !important; }
.mb-05 { margin-bottom: calc( 0.5rem / 2 ) !important; }
.ms-05 { margin-left: calc( 0.5rem / 2 ) !important; }

.m-1 { margin: calc( 1rem / 2 ) !important; }
.mt-1 { margin-top: calc( 1rem / 2 ) !important; }
.me-1 { margin-right: calc( 1rem / 2 ) !important; }
.mb-1 { margin-bottom: calc( 1rem / 2 ) !important; }
.ms-1 { margin-left: calc( 1rem / 2 ) !important; }

.m-15 { margin: calc( 1.5rem / 2 ) !important; }
.mt-15 { margin-top: calc( 1.5rem / 2 ) !important; }
.me-15 { margin-right: calc( 1.5rem / 2 ) !important; }
.mb-15 { margin-bottom: calc( 1.5rem / 2 ) !important; }
.ms-15 { margin-left: calc( 1.5rem / 2 ) !important; }

.m-2 { margin: calc( 2rem / 2 ) !important; }
.mt-2 { margin-top: calc( 2rem / 2 ) !important; }
.me-2 { margin-right: calc( 2rem / 2 ) !important; }
.mb-2 { margin-bottom: calc( 2rem / 2 ) !important; }
.ms-2 { margin-left: calc( 2rem / 2 ) !important; }

.m-25 { margin: calc( 2.5rem / 2 ) !important; }
.mt-25 { margin-top: calc( 2.5rem / 2 ) !important; }
.me-25 { margin-right: calc( 2.5rem / 2 ) !important; }
.mb-25 { margin-bottom: calc( 2.5rem / 2 ) !important; }
.ms-25 { margin-left: calc( 2.5rem / 2 ) !important; }

.m-3 { margin: calc( 3rem / 2 ) !important; }
.mt-3 { margin-top: calc( 3rem / 2 ) !important; }
.me-3 { margin-right: calc( 3rem / 2 ) !important; }
.mb-3 { margin-bottom: calc( 3rem / 2 ) !important; }
.ms-3 { margin-left: calc( 3rem / 2 ) !important; }

.m-35 { margin: calc( 3.5rem / 2 ) !important; }
.mt-35 { margin-top: calc( 3.5rem / 2 ) !important; }
.me-35 { margin-right: calc( 3.5rem / 2 ) !important; }
.mb-35 { margin-bottom: calc( 3.5rem / 2 ) !important; }
.ms-35 { margin-left: calc( 3.5rem / 2 ) !important; }

.m-4 { margin: calc( 4rem / 2 ) !important; }
.mt-4 { margin-top: calc( 4rem / 2 ) !important; }
.me-4 { margin-right: calc( 4rem / 2 ) !important; }
.mb-4 { margin-bottom: calc( 4rem / 2 ) !important; }
.ms-4 { margin-left: calc( 4rem / 2 ) !important; }

.m-5 { margin: calc( 5rem / 2 ) !important; }
.mt-5 { margin-top: calc( 5rem / 2 ) !important; }
.me-5 { margin-right: calc( 5rem / 2 ) !important; }
.mb-5 { margin-bottom: calc( 5rem / 2 ) !important; }
.ms-5 { margin-left: calc( 5rem / 2 ) !important; }

.m-6 { margin: calc( 6rem / 2 ) !important; }
.mt-6 { margin-top: calc( 6rem / 2 ) !important; }
.me-6 { margin-right: calc( 6rem / 2 ) !important; }
.mb-6 { margin-bottom: calc( 6rem / 2 ) !important; }
.ms-6 { margin-left: calc( 6rem / 2 ) !important; }

.m-7 { margin: calc( 7rem / 2 ) !important; }
.mt-7 { margin-top: calc( 7rem / 2 ) !important; }
.me-7 { margin-right: calc( 7rem / 2 ) !important; }
.mb-7 { margin-bottom: calc( 7rem / 2 ) !important; }
.ms-7 { margin-left: calc( 7rem / 2 ) !important; }

.m-8 { margin: calc( 8rem / 2 ) !important; }
.mt-8 { margin-top: calc( 8rem / 2 ) !important; }
.me-8 { margin-right: calc( 8rem / 2 ) !important; }
.mb-8 { margin-bottom: calc( 8rem / 2 ) !important; }
.ms-8 { margin-left: calc( 8rem / 2 ) !important; }

@media (min-width: 768px) {

	.m-05 { margin: 0.5rem !important; }
	.mt-05 { margin-top: 0.5rem !important; }
	.me-05 { margin-right: 0.5rem !important; }
	.mb-05 { margin-bottom: 0.5rem !important; }
	.ms-05 { margin-left: 0.5rem !important; }

	.m-1 { margin: 1rem !important; }
	.mt-1 { margin-top: 1rem !important; }
	.me-1 { margin-right: 1rem !important; }
	.mb-1 { margin-bottom: 1rem !important; }
	.ms-1 { margin-left: 1rem !important; }

	.m-15 { margin: 1.5rem !important; }
	.mt-15 { margin-top: 1.5rem !important; }
	.me-15 { margin-right: 1.5rem !important; }
	.mb-15 { margin-bottom: 1.5rem !important; }
	.ms-15 { margin-left: 1.5rem !important; }

	.m-2 { margin: 2rem !important; }
	.mt-2 { margin-top: 2rem !important; }
	.me-2 { margin-right: 2rem !important; }
	.mb-2 { margin-bottom: 2rem !important; }
	.ms-2 { margin-left: 2rem !important; }

	.m-25 { margin: 2.5rem !important; }
	.mt-25 { margin-top: 2.5rem !important; }
	.me-25 { margin-right: 2.5rem !important; }
	.mb-25 { margin-bottom: 2.5rem !important; }
	.ms-25 { margin-left: 2.5rem !important; }

	.m-3 { margin: 3rem !important; }
	.mt-3 { margin-top: 3rem !important; }
	.me-3 { margin-right: 3rem !important; }
	.mb-3 { margin-bottom: 3rem !important; }
	.ms-3 { margin-left: 3rem !important; }

	.m-35 { margin: 3.5rem !important; }
	.mt-35 { margin-top: 3.5rem !important; }
	.me-35 { margin-right: 3.5rem !important; }
	.mb-35 { margin-bottom: 3.5rem !important; }
	.ms-35 { margin-left: 3.5rem !important; }

	.m-4 { margin: 4rem !important; }
	.mt-4 { margin-top: 4rem !important; }
	.me-4 { margin-right: 4rem !important; }
	.mb-4 { margin-bottom: 4rem !important; }
	.ms-4 { margin-left: 4rem !important; }

	.m-5 { margin: 5rem !important; }
	.mt-5 { margin-top: 5rem !important; }
	.me-5 { margin-right: 5rem !important; }
	.mb-5 { margin-bottom: 5rem !important; }
	.ms-5 { margin-left: 5rem !important; }

	.m-6 { margin: 6rem !important; }
	.mt-6 { margin-top: 6rem !important; }
	.me-6 { margin-right: 6rem !important; }
	.mb-6 { margin-bottom: 6rem !important; }
	.ms-6 { margin-left: 6rem !important; }

	.m-7 { margin: 7rem !important; }
	.mt-7 { margin-top: 7rem !important; }
	.me-7 { margin-right: 7rem !important; }
	.mb-7 { margin-bottom: 7rem !important; }
	.ms-7 { margin-left: 7rem !important; }

	.m-8 { margin: 8rem !important; }
	.mt-8 { margin-top: 8rem !important; }
	.me-8 { margin-right: 8rem !important; }
	.mb-8 { margin-bottom: 8rem !important; }
	.ms-8 { margin-left: 8rem !important; }
}

.p-0 { padding: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.pe-0 { padding-right: 0 !important; }
.pb-0 { padding-bottom: 0 !important; }
.ps-0 { padding-left: 0 !important; }

.p-05 { padding: calc( 0.5rem / 2 ) !important; }
.pt-05 { padding-top: calc( 0.5rem / 2 ) !important; }
.pe-05 { padding-right: calc( 0.5rem / 2 ) !important; }
.pb-05 { padding-bottom: calc( 0.5rem / 2 ) !important; }
.ps-05 { padding-left: calc( 0.5rem / 2 ) !important; }

.p-1 { padding: calc( 1rem / 2 ) !important; }
.pt-1 { padding-top: calc( 1rem / 2 ) !important; }
.pe-1 { padding-right: calc( 1rem / 2 ) !important; }
.pb-1 { padding-bottom: calc( 1rem / 2 ) !important; }
.ps-1 { padding-left: calc( 1rem / 2 ) !important; }

.p-15 { padding: calc( 1.5rem / 2 ) !important; }
.pt-15 { padding-top: calc( 1.5rem / 2 ) !important; }
.pe-15 { padding-right: calc( 1.5rem / 2 ) !important; }
.pb-15 { padding-bottom: calc( 1.5rem / 2 ) !important; }
.ps-15 { padding-left: calc( 1.5rem / 2 ) !important; }

.p-2 { padding: calc( 2rem / 2 ) !important; }
.pt-2 { padding-top: calc( 2rem / 2 ) !important; }
.pe-2 { padding-right: calc( 2rem / 2 ) !important; }
.pb-2 { padding-bottom: calc( 2rem / 2 ) !important; }
.ps-2 { padding-left: calc( 2rem / 2 ) !important; }

.p-25 { padding: calc( 2.5rem / 2 ) !important; }
.pt-25 { padding-top: calc( 2.5rem / 2 ) !important; }
.pe-25 { padding-right: calc( 2.5rem / 2 ) !important; }
.pb-25 { padding-bottom: calc( 2.5rem / 2 ) !important; }
.ps-25 { padding-left: calc( 2.5rem / 2 ) !important; }

.p-3 { padding: calc( 3rem / 2 ) !important; }
.pt-3 { padding-top: calc( 3rem / 2 ) !important; }
.pe-3 { padding-right: calc( 3rem / 2 ) !important; }
.pb-3 { padding-bottom: calc( 3rem / 2 ) !important; }
.ps-3 { padding-left: calc( 3rem / 2 ) !important; }

.p-35 { padding: calc( 3.5rem / 2 ) !important; }
.pt-35 { padding-top: calc( 3.5rem / 2 ) !important; }
.pe-35 { padding-right: calc( 3.5rem / 2 ) !important; }
.pb-35 { padding-bottom: calc( 3.5rem / 2 ) !important; }
.ps-35 { padding-left: calc( 3.5rem / 2 ) !important; }

.p-4 { padding: calc( 4rem / 2 ) !important; }
.pt-4 { padding-top: calc( 4rem / 2 ) !important; }
.pe-4 { padding-right: calc( 4rem / 2 ) !important; }
.pb-4 { padding-bottom: calc( 4rem / 2 ) !important; }
.ps-4 { padding-left: calc( 4rem / 2 ) !important; }

.p-5 { padding: calc( 5rem / 2 ) !important; }
.pt-5 { padding-top: calc( 5rem / 2 ) !important; }
.pe-5 { padding-right: calc( 5rem / 2 ) !important; }
.pb-5 { padding-bottom: calc( 5rem / 2 ) !important; }
.ps-5 { padding-left: calc( 5rem / 2 ) !important; }

.p-6 { padding: calc( 6rem / 2 ) !important; }
.pt-6 { padding-top: calc( 6rem / 2 ) !important; }
.pe-6 { padding-right: calc( 6rem / 2 ) !important; }
.pb-6 { padding-bottom: calc( 6rem / 2 ) !important; }
.ps-6 { padding-left: calc( 6rem / 2 ) !important; }

.p-7 { padding: calc( 7rem / 2 ) !important; }
.pt-7 { padding-top: calc( 7rem / 2 ) !important; }
.pe-7 { padding-right: calc( 7rem / 2 ) !important; }
.pb-7 { padding-bottom: calc( 7rem / 2 ) !important; }
.ps-7 { padding-left: calc( 7rem / 2 ) !important; }

.p-8 { padding: calc( 8rem / 2 ) !important; }
.pt-8 { padding-top: calc( 8rem / 2 ) !important; }
.pe-8 { padding-right: calc( 8rem / 2 ) !important; }
.pb-8 { padding-bottom: calc( 8rem / 2 ) !important; }
.ps-8 { padding-left: calc( 8rem / 2 ) !important; }

.py-vh-25 { padding-top: calc( 25vh / 2 ) !important; padding-bottom: calc( 25vh / 2 ) !important; }

@media (min-width: 768px) {

	.p-05 { padding: 0.5rem !important; }
	.pt-05 { padding-top: 0.5rem !important; }
	.pe-05 { padding-right: 0.5rem !important; }
	.pb-05 { padding-bottom: 0.5rem !important; }
	.ps-05 { padding-left: 0.5rem !important; }

	.p-1 { padding: 1rem !important; }
	.pt-1 { padding-top: 1rem !important; }
	.pe-1 { padding-right: 1rem !important; }
	.pb-1 { padding-bottom: 1rem !important; }
	.ps-1 { padding-left: 1rem !important; }

	.p-15 { padding: 1.5rem !important; }
	.pt-15 { padding-top: 1.5rem !important; }
	.pe-15 { padding-right: 1.5rem !important; }
	.pb-15 { padding-bottom: 1.5rem !important; }
	.ps-15 { padding-left: 1.5rem !important; }

	.p-2 { padding: 2rem !important; }
	.pt-2 { padding-top: 2rem !important; }
	.pe-2 { padding-right: 2rem !important; }
	.pb-2 { padding-bottom: 2rem !important; }
	.ps-2 { padding-left: 2rem !important; }

	.p-25 { padding: 2.5rem !important; }
	.pt-25 { padding-top: 2.5rem !important; }
	.pe-25 { padding-right: 2.5rem !important; }
	.pb-25 { padding-bottom: 2.5rem !important; }
	.ps-25 { padding-left: 2.5rem !important; }

	.p-3 { padding: 3rem !important; }
	.pt-3 { padding-top: 3rem !important; }
	.pe-3 { padding-right: 3rem !important; }
	.pb-3 { padding-bottom: 3rem !important; }
	.ps-3 { padding-left: 3rem !important; }

	.p-35 { padding: 3.5rem !important; }
	.pt-35 { padding-top: 3.5rem !important; }
	.pe-35 { padding-right: 3.5rem !important; }
	.pb-35 { padding-bottom: 3.5rem !important; }
	.ps-35 { padding-left: 3.5rem !important; }

	.p-4 { padding: 4rem !important; }
	.pt-4 { padding-top: 4rem !important; }
	.pe-4 { padding-right: 4rem !important; }
	.pb-4 { padding-bottom: 4rem !important; }
	.ps-4 { padding-left: 4rem !important; }

	.p-5 { padding: 5rem !important; }
	.pt-5 { padding-top: 5rem !important; }
	.pe-5 { padding-right: 5rem !important; }
	.pb-5 { padding-bottom: 5rem !important; }
	.ps-5 { padding-left: 5rem !important; }

	.p-6 { padding: 6rem !important; }
	.pt-6 { padding-top: 6rem !important; }
	.pe-6 { padding-right: 6rem !important; }
	.pb-6 { padding-bottom: 6rem !important; }
	.ps-6 { padding-left: 6rem !important; }

	.p-7 { padding: 7rem !important; }
	.pt-7 { padding-top: 7rem !important; }
	.pe-7 { padding-right: 7rem !important; }
	.pb-7 { padding-bottom: 7rem !important; }
	.ps-7 { padding-left: 7rem !important; }

	.p-8 { padding: 8rem !important; }
	.pt-8 { padding-top: 8rem !important; }
	.pe-8 { padding-right: 8rem !important; }
	.pb-8 { padding-bottom: 8rem !important; }
	.ps-8 { padding-left: 8rem !important; }

	.py-vh-25 { padding-top: 25vh !important; padding-bottom: 25vh !important; }
}

.align-h-left { display: flex; justify-content: flex-start !important; }
.align-h-center { display: flex; justify-content: center !important; }
.align-h-right { display: flex; justify-content: flex-end !important; }
.align-h-between { display: flex; justify-content: space-between !important; }
.align-h-around { display: flex; justify-content: space-around !important; }
.align-v-top { display: flex; align-items: flex-start !important; }
.align-v-center { display: flex; align-items: center !important; }
.align-v-bottom { display: flex; align-items: flex-end !important; }
.align-v-between { display: flex; align-items: space-between !important; }
.align-v-around { display: flex; align-items: space-around !important; }

/* #endregion Layout */

/*----------------------------------------------
3. Color
----------------------------------------------*/

/* #region Color */

.primary { --color: var(--primary-color) !important; }
.primary-hover { --hover-color: var(--primary-color) !important; }

.primary-15 { --color: var(--primary-15-color) !important; }
.primary-15-hover { --hover-color: var(--primary-15-color) !important; }

.primary-25 { --color: var(--primary-25-color) !important; }
.primary-25-hover { --hover-color: var(--primary-25-color) !important; }

.primary-50 { --color: var(--primary-50-color) !important; }
.primary-50-hover { --hover-color: var(--primary-50-color) !important; }

.primary-75 { --color: var(--primary-75-color) !important; }
.primary-75-hover { --hover-color: var(--primary-75-color) !important; }

.secondary { --color: var(--secondary-color) !important; }
.secondary-hover { --hover-color: var(--secondary-color) !important; }

.secondary-15 { --color: var(--secondary-15-color) !important; }
.secondary-15-hover { --hover-color: var(--secondary-15-color) !important; }

.secondary-25 { --color: var(--secondary-25-color) !important; }
.secondary-25-hover { --hover-color: var(--secondary-25-color) !important; }

.secondary-50 { --color: var(--secondary-50-color) !important; }
.secondary-50-hover { --hover-color: var(--secondary-50-color) !important; }

.secondary-75 { --color: var(--secondary-75-color) !important; }
.secondary-75-hover { --hover-color: var(--secondary-75-color) !important; }

.tertiary { --color: var(--tertiary-color) !important; }
.tertiary-hover { --hover-color: var(--tertiary-color) !important; }

.tertiary-15 { --color: var(--tertiary-15-color) !important; }
.tertiary-15-hover { --hover-color: var(--tertiary-15-color) !important; }

.tertiary-25 { --color: var(--tertiary-25-color) !important; }
.tertiary-25-hover { --hover-color: var(--tertiary-25-color) !important; }

.tertiary-50 { --color: var(--tertiary-50-color) !important; }
.tertiary-50-hover { --hover-color: var(--tertiary-50-color) !important; }

.tertiary-75 { --color: var(--tertiary-75-color) !important; }
.tertiary-75-hover { --hover-color: var(--tertiary-75-color) !important; }

.accent { --color: var(--accent-color) !important; }
.accent-hover { --hover-color: var(--accent-color) !important; }

.white { --color: var(--white-color) !important; }
.white-hover { --hover-color: var(--white-color) !important; }

.white-10 { --color: var(--white-10-color) !important; }
.white-10-hover { --hover-color: var(--white-10-color) !important; }

.white-25 { --color: var(--white-25-color) !important; }
.white-25-hover { --hover-color: var(--white-25-color) !important; }

.white-50 { --color: var(--white-50-color) !important; }
.white-50-hover { --hover-color: var(--white-50-color) !important; }

.white-65 { --color: var(--white-65-color) !important; }
.white-65-hover { --hover-color: var(--white-65-color) !important; }

.white-75 { --color: var(--white-75-color) !important; }
.white-75-hover { --hover-color: var(--white-75-color) !important; }

.white-85 { --color: var(--white-85-color) !important; }
.white-85-hover { --hover-color: var(--white-85-color) !important; }

.gray { --color: var(--gray-color) !important; }
.gray-hover { --hover-color: var(--gray-color) !important; }

.gray-10 { --color: var(--gray-10-color) !important; }
.gray-10-hover { --hover-color: var(--gray-10-color) !important; }

.gray-25 { --color: var(--gray-25-color) !important; }
.gray-25-hover { --hover-color: var(--gray-25-color) !important; }

.gray-50 { --color: var(--gray-50-color) !important; }
.gray-50-hover { --hover-color: var(--gray-50-color) !important; }

.gray-65 { --color: var(--gray-65-color) !important; }
.gray-65-hover { --hover-color: var(--gray-65-color) !important; }

.gray-75 { --color: var(--gray-75-color) !important; }
.gray-75-hover { --hover-color: var(--gray-75-color) !important; }

.gray-85 { --color: var(--gray-85-color) !important; }
.gray-85-hover { --hover-color: var(--gray-85-color) !important; }

.black { --color: var(--black-color) !important; }
.black-hover { --hover-color: var(--black-color) !important; }

.black-10 { --color: var(--black-10-color) !important; }
.black-10-hover { --hover-color: var(--black-10-color) !important; }

.black-25 { --color: var(--black-25-color) !important; }
.black-25-hover { --hover-color: var(--black-25-color) !important; }

.black-50 { --color: var(--black-50-color) !important; }
.black-50-hover { --hover-color: var(--black-50-color) !important; }

.black-65 { --color: var(--black-65-color) !important; }
.black-65-hover { --hover-color: var(--black-65-color) !important; }

.black-75 { --color: var(--black-75-color) !important; }
.black-75-hover { --hover-color: var(--black-75-color) !important; }

.black-85 { --color: var(--black-85-color) !important; }
.black-85-hover { --hover-color: var(--black-85-color) !important; }

.transparent { --color: transparent !important; }

/* #endregion Color */

/*----------------------------------------------
4. Style
----------------------------------------------*/

/* #region Style */

:root .gradient.scheme-1,
:root .bg-gradient.scheme-1,
:root .border-gradient.scheme-1,
:root .text-gradient.scheme-1 {
	--color-1: var(--primary-color);
	--color-2: var(--secondary-color);
}

:root .gradient.scheme-1.invert-gradient,
:root .bg-gradient.scheme-1.invert-gradient,
:root .border-gradient.scheme-1.invert-gradient,
:root .text-gradient.scheme-1.invert-gradient {
	--color-2: var(--primary-color);
	--color-1: var(--secondary-color);
}

:root .gradient.scheme-2,
:root .bg-gradient.scheme-2,
:root .border-gradient.scheme-2,
:root .text-gradient.scheme-2 {
	--color-1: var(--secondary-color);
	--color-2: var(--tertiary-color);
}

:root .gradient.scheme-2.invert-gradient,
:root .bg-gradient.scheme-2.invert-gradient,
:root .border-gradient.scheme-2.invert-gradient,
:root .text-gradient.scheme-2.invert-gradient {
	--color-2: var(--secondary-color);
	--color-1: var(--tertiary-color);
}

:root .gradient.scheme-3,
:root .bg-gradient.scheme-3,
:root .border-gradient.scheme-3,
:root .text-gradient.scheme-3 {
	--color-1: var(--tertiary-color);
	--color-2: var(--primary-color);
}

:root .gradient.scheme-3.invert-gradient,
:root .bg-gradient.scheme-3.invert-gradient,
:root .border-gradient.scheme-3.invert-gradient,
:root .text-gradient.scheme-3.invert-gradient {
	--color-2: var(--tertiary-color);
	--color-1: var(--primary-color);
}

.text-italic {
	font-style: italic;
}

.text-normal {
	font-weight: 100;
	-webkit-text-fill-color: initial;
	-webkit-text-stroke-width:initial;
}

.text-outline {
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
}

.text-offset {
	position: relative;
	display: flex;
	justify-content: center;
}

.text-offset .filled {
	position: relative;
	width: -webkit-fill-available;
	width: -moz-available;
	height: auto;
	z-index: 0;
	-webkit-text-stroke-width: 1px;
	-webkit-text-fill-color: var(--color);
	color: var(--color);
}

.text-offset .outline {
	position: absolute;
	width: -webkit-fill-available;
	width: -moz-available;
	height: auto;
	z-index: 1;
	top: 5px;
	left: 5px;
	-webkit-text-stroke-width: 1px;
	-webkit-text-fill-color: transparent;
	color: var(--color);
}

.text-shadow {
	position: relative;
	display: flex;
	justify-content: center;
}

.text-shadow .filled {
	position: relative;
	width: -webkit-fill-available;
	width: -moz-available;
	height: auto;
	z-index: 1;
	overflow: hidden;
	-webkit-text-fill-color: var(--color);
	-webkit-text-stroke-width: 1px;
	color: var(--color);
}

.text-shadow .overlay {
	position: absolute;
	width: -webkit-fill-available;
	width: -moz-available;
	height: auto;
	z-index: 0;
	top: 5px;
	left: 5px;
	color: var(--color);
}

.text-half-filled {
	position: relative;
	height: 110px;
	display: flex;
	justify-content: center;
}

.text-half-filled .filled {
	position: absolute;
	z-index: 1;
	width: -webkit-fill-available;
	width: -moz-available;
	height: 55%;
	overflow: hidden;
	-webkit-text-stroke-width: 1px;
	-webkit-text-fill-color: var(--color);
	color: var(--color);
}

.text-half-filled .outline {
	position: absolute;
	width: -webkit-fill-available;
	width: -moz-available;
	height: auto;
	z-index: 0;
	-webkit-text-stroke-width: 1px;
	-webkit-text-fill-color: transparent;
	color: var(--color);
}

.text-gradient, .text-gradient > * {
	background-image: -webkit-linear-gradient(45deg, var(--color-1) 0%, var(--color-2) 100%);
	background-image: linear-gradient(45deg, var(--color-1) 0%, var(--color-2) 100%);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-outline.text-gradient, .text-outline.text-gradient > * {
	padding: 0 0.3rem;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	-webkit-text-stroke: transparent;
	-webkit-text-stroke-width: 4px;
	-webkit-text-fill-color: inherit;
	color: var(--color);
}

.text-color {
	color: var(--color);
}

.bg-color {
	background-color: var(--color) !important;
}

.bg-gradient {
	background-image: -webkit-linear-gradient(45deg, var(--color-1) 0%, var(--color-2) 100%) !important;
	background-image: linear-gradient(45deg, var(--color-1) 0%, var(--color-2) 100%) !important;
}

.bg-image {
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
}

.bg-image.position-x-right {
	background-position-x: right;
}

.bg-image.position-x-left {
	background-position-x: left;
}

.bg-image.position-y-top {
	background-position-y: top;
}

.bg-image.position-y-bottom {
	background-position-y: bottom;
}

.bg-image.bg-fixed {
	background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
	.bg-image.bg-fixed {
		background-attachment: initial;
	}
}

.bg-image.size-50 {
	background-size: 50%;
	background-repeat: no-repeat;
	background-position-y: 0;
}

.has-overlay .overlay {
	content: '';
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color);
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.shadow {
	box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 15%) !important;
}

.small-shadow {
	box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%) !important;
}

.drop-shadow {	
	filter: drop-shadow(5px 5px 5px var(--color));
}

.grayscale {
	filter: grayscale(1);
}

.invert-color {
	filter: brightness(1) invert(1);
}

.grayscale-invert-color {
	filter: grayscale(1) invert(1);
}

.mix-blend-normal { mix-blend-mode: normal; }
.mix-blend-multiply { mix-blend-mode: multiply; }
.mix-blend-screen { mix-blend-mode: screen; }
.mix-blend-overlay { mix-blend-mode: overlay; }
.mix-blend-darken { mix-blend-mode: darken; }
.mix-blend-lighten { mix-blend-mode: lighten; }
.mix-blend-lighter { mix-blend-mode: plus-lighter; }
.mix-blend-color-dodge { mix-blend-mode: color-dodge; }
.mix-blend-color-burn { mix-blend-mode: color-burn; }
.mix-blend-hard-light { mix-blend-mode: hard-light; }
.mix-blend-soft-light { mix-blend-mode: soft-light; }
.mix-blend-difference { mix-blend-mode: difference; }
.mix-blend-exclusion { mix-blend-mode: exclusion; }
.mix-blend-hue { mix-blend-mode: hue; }
.mix-blend-saturation { mix-blend-mode: saturation; }
.mix-blend-color { mix-blend-mode: color; }
.mix-blend-luminosity { mix-blend-mode: luminosity; }
.mix-blend-auto { mix-blend-mode: difference; filter: brightness(1) invert(1); }

@media (max-width: 767px) {

	.text-half-filled {
    height: 60px;
	}

	.bg-image.bg-only-desktop {
    background-size: 0;
	}
}

/* #endregion Style */

/*----------------------------------------------
5. Animation
----------------------------------------------*/

/* #region Animation */

@keyframes show-up-animation {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -50px;
  }
}

@-webkit-keyframes show-up-animation {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -50px;
  }
}

@keyframes show-down-animation {
  from {
    margin-top: -50px;
  }
  to {
    margin-top: 0;
  }
}

@-webkit-keyframes show-down-animation {
  from {
    margin-top: -50px;
  }
  to {
    margin-top: 0;
  }
}

.show-up-animation {
  animation-name: show-up-animation;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.show-down-animation {
  animation-name: show-down-animation;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce-in-up-animation {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce-in-up-animation {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounce-in-up-animation {
  -webkit-animation-name: bounce-in-up-animation;
  animation-name: bounce-in-up-animation;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce-out-down-animation {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
}

@keyframes bounce-out-down-animation {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
}

.bounce-out-down-animation {
  -webkit-animation-name: bounce-out-down-animation;
  animation-name: bounce-out-down-animation;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes scale-rotate-animation {
	to {
		opacity: 0;
		transform: scale3d(0.5, 0.5, 1) rotate(360deg);
	}
}

@-webkit-keyframes scale-rotate-animation {
	to {
		opacity: 0;
		transform: scale3d(0.5, 0.5, 1) rotate(360deg);
	}
}

@keyframes width-animation {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-webkit-keyframes width-animation {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes up-down-animation {
	from {
		transform: translatey(0px);
	}
	to {
		transform: translatey(-20px);
	}
}

@-webkit-keyframes up-down-animation {
	from {
		transform: translatey(0px);
	}
	to {
		transform: translatey(-20px);
	}
}

@keyframes up-down-smooth-animation {
	from {
		transform: translatey(0px);
	}
	to {
		transform: translatey(-10px);
	}
}

@-webkit-keyframes up-down-smooth-animation {
	from {
		transform: translatey(0px);
	}
	to {
		transform: translatey(-10px);
	}
}

@keyframes left-animation {
	0% {
		left: 100%;
	}

	100% {
		left: 0;
	}
}

@-webkit-keyframes left-animation {
	0% {
		left: 100%;
	}

	100% {
		left: 0;
	}
}

@keyframes cross-left-animation {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes cross-left-animation {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes cross-right-animation {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes cross-right-animation {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes horizontal-line-animation {
  20%, 100% {
    opacity: 0;
    left: 100%;
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

@-webkit-keyframes horizontal-line-animation {
  20%, 100% {
    opacity: 0;
    left: 100%;
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

@keyframes vertical-line-animation {
  20%, 100% {
    opacity: 0;
    top: 100%;
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

@-webkit-keyframes vertical-line-animation {
  20%, 100% {
    opacity: 0;
    top: 100%;
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

@keyframes expand-animation {
	0% {
		margin-left: 10px;
		margin-right: 10px;
	}
	50% {
		margin-left: 15px;
		margin-right: 15px;
	}
	100% {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@-webkit-keyframes expand-animation {
	0% {
		margin-left: 10px;
		margin-right: 10px;
	}
	50% {
		margin-left: 15px;
		margin-right: 15px;
	}
	100% {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@keyframes spin-animation {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@-webkit-keyframes spin-animation {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes scroll-down-animation {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes scroll-down-animation {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		-webkit-transform: translate(0, 20px);
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes rotate-animation {
	0% {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
	}

	100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
	}
}

@-webkit-keyframes rotate-animation {
	0% {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
	}

	100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
	}
}

/* #endregion Animation */

/*----------------------------------------------
6. Hover
----------------------------------------------*/

/* #region Hover */

.hover-show {
	opacity: 0 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hover-show:hover,
.parent:hover .hover-show {
	opacity: 1 !important;
}

.hover-opacity {
	opacity: 0.5 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hover-opacity:hover,
.parent:hover .hover-opacity {
	opacity: 1 !important;
}

.hover-grayscale {
	filter: grayscale(1);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hover-grayscale:hover {
	filter: grayscale(0);
}

.hover-rotate:hover {
	display: block;
	-moz-transition: all 0.6s cubic-bezier(0.99, 0, 0.57, 0.94);
	-o-transition: all 0.6s cubic-bezier(0.99, 0, 0.57, 0.94);
	-webkit-transition: all 0.6s cubic-bezier(0.99, 0, 0.57, 0.94);
	transition: all 0.6s cubic-bezier(0.99, 0, 0.57, 0.94);
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}

.button .hover-rotate {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.button .hover-rotate:hover {
	display: flex;
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-webkit-transform: rotate(0);
	transform: rotate(0);
	animation: rotate-animation 0.6s forwards ease-in-out;
}

.parent:hover .hover-scale {
	transform: scale(1.05);
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.hover-zoom:hover .image,
.parent:hover .hover-zoom .image {
	transform: scale(1.1);
	transition-duration: 15s;
}

.hover-zoom-rotate:hover .image,
.parent:hover .hover-zoom-rotate .image {
	transform: scale(1.1) rotate(3deg);
	transition-duration: 15s;
}

.hover-up {
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hover-up:hover {
	transform: translateY(-10px);
}

.hover-up-down:hover,
.parent:hover .hover-up-down {
	animation: hover-up-down 0.4s forwards ease-in-out;
}

.image-hover-scroll {
  max-height: 365px;
  overflow: hidden;
}

.image-hover-scroll .image {
  width: 100%;
	-webkit-transition: top 11s;
	-o-transition: top 11s;
	-moz-transition: top 11s;
	transition: transform 1.5s cubic-bezier(0.4, 0, 1, 1);
}

.image-hover-scroll:hover .image {
  transform: translateY(calc(-100% + 365px));
  -webkit-transition: all 11s;
  -o-transition: all 11s;
  -moz-transition: all 11s;
	transition: transform 3s cubic-bezier(0.4, 0, 1, 1);
}

@keyframes hover-up-down {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
	0% {
		transform: translateY(0);
	}
}

/* #endregion Hover */

/*----------------------------------------------
7. Helper
----------------------------------------------*/

/* #region Helpers */

.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }

.line-clamp-1 {
	max-width: 90%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.line-clamp-2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.line-clamp-3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}

.link {
	position: relative;
	z-index: 5;
	text-decoration: none;
	color: var(--color);
	pointer-events: all;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.link:hover,
.link:hover > * {
	color: var(--hover-color);
}

.link .icon {
	margin-right: 7.5px;
	vertical-align: middle;
	font-size: 0.75rem;
}

.holder {
	position: relative;
	width: 100%;
}

.margin-holder {
	margin-left: 2rem;
}

.grid-holder-1-col,
.grid-holder-2-col,
.grid-holder-3-col,
.grid-holder-4-col,
.grid-holder-5-col,
.grid-holder-6-col {
	width: 100%;
	margin-bottom: 5rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.half-section {
	position: relative;
	width: 50%;
	height: 100vh;
	padding: 4rem;
}

.section-inner {
	position: relative;
	padding: 35px;
}

.section-inner-expanded {
	padding: 35px 0;
}

.stretched {
	margin: -15px !important;
}

.stretched-top {
	margin-top: -15px;
}

.stretched-side {
	margin-right: -15px;
	margin-left: -15px;
}

.stretched-bottom {
	margin-bottom: -15px;
}

.gapped-grid {
	width: calc(100% + 26px);
	max-width: calc(100% + 26px);
	margin-left: -13px;
}

.gapped-grid .item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 25px;
}

.climb {
	position: relative;
	top: -5rem;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: -5rem;
	z-index: 12;
}

.climb.simple {
	top: -3rem;
	margin-bottom: 0;
}

.flex-item {
	display: flex;
	justify-content: start;
	align-items: center;
	margin: 1.5rem 0;
}

.flex-item .icon {
	margin-right: 10px;
}

.fit-cover {
	object-fit: cover;
	object-position: center;
}

.cursor-pointer {
	cursor: pointer;
}

.one-page-item {
	position: absolute;
	top: 5%;
	width: 100%;
	height: 50%;
	display: block;
	visibility: hidden;
	pointer-events: none;
}

@media (max-width: 1199px) {

	.half-section {
		padding: 2rem;
	}
}

@media (max-width: 991px) {
	
	.d-only-desktop {
		display: none !important;
	}
	
	.d-only-mobile {
		display: inline !important;
	}
	
	.grid-holder-1-col,
	.grid-holder-2-col,
	.grid-holder-3-col,
	.grid-holder-4-col,
	.grid-holder-5-col,
	.grid-holder-6-col {
		display: inline-block;
		text-align: center;
	}
	
	.grid-holder-1-col > *,
	.grid-holder-2-col > *,
	.grid-holder-3-col > *,
	.grid-holder-4-col > *,
	.grid-holder-5-col > *,
	.grid-holder-6-col > * {
		margin: 1rem !important;
	}
}

@media (max-width: 767px) {

	.half-section {
    width: 100%;
	}

	.half-section .label-vertical {
		display: none;
	}

	.climb {
		top: -4rem;
		margin-bottom: -4rem;
	}

	.stretched-side-on-mobile {
		margin-right: -15px !important;
		margin-left: -15px !important;
	}

	.margin-holder {
		margin-left: 0;
	}
}

/* #endregion Helpers */